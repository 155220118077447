import styled from "styled-components";
import Grid from "Components/Atoms/Grid";
import Container from "Components/Atoms/Container";

export const PageContainer = styled(Container)`
    padding-top:80px;
    padding-bottom:100px;
    line-height: 1.4em;
    text-align:left;
    color: ${(props) => props.theme.palette.orient};
`;

export const Title = styled.div`
    font-size: 40px;

`;

export const LinkBack = styled.a`
    font-size: 20px;
    margin-top:20px;
    display:block;
    color: ${(props) => props.theme.palette.bostonBlue};

`;

export const Col = styled(Grid)``;

export const Row = styled(Grid)``;
