import styled from "styled-components";
import Grid from "Components/Atoms/Grid";
import { device } from "Theme";

export const Container = styled(Grid)`
    width: 100%;
    @media ${device.tablet} {
        margin: auto;
        width: 768px;
    }

    @media ${device.laptop} {
        width: 1024px;
    }

    @media ${device.laptopL} {
        width: 1270px;
    }

    @media ${device.desktop} {
        width: 1440px;
    }
`;
