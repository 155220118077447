import React, { useEffect } from "react";
import Page404 from "Screens/404";

const PageNotFound = (props) => {
    return <Page404 {...props} />;
};

export async function getStaticProps() {

    return {
        props: {},
        revalidate: 3,
    };
}

export default PageNotFound;
