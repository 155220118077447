import React from "react";
import LayoutPage from "Components/Organisms/LayoutPage";
import Seo from "Components/Atoms/Seo";
import Link from "next/link";
import * as S from "./styles";

const Page404 = ({ ...props }) => {

    return (
        <LayoutPage showHeader={false}>
            <Seo title={"Erro 404 - Página não encontrada"} />
            <S.PageContainer>
                <S.Title>Página não encontrada</S.Title>
                <Link href={"/"} passHref>
                    <S.LinkBack>« voltar para pagina inicial</S.LinkBack>
                </Link>
            </S.PageContainer>
        </LayoutPage>
    );
};
export default Page404;
